import { useAPIFetch } from '~/composables/use-api-fetch'

// const route = useRouter().currentRoute.value
const router = useRouter()
const route = router.currentRoute.value
const localeRoute = useLocaleRoute()
export const useSearch = definePiniaStore('search', {
  state: () => ({
    searchResults: [],
    loading: false,
  }),
  actions: {
    clearResults() {
      this.searchResults = []
    },
    async search(query: string) {
      this.loading = true
      const { data, refresh }: any = await useAPIFetch(
        'umbraco/api/SearchContentApi/Search',
        {
          query: {
            query,
          },
        }
      )
      await refresh()
      if (data.value) {
        this.searchResults = data.value.splice(0, 10)
        this.loading = false
      }
    },
  },
})
